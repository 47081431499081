'use client'

import { useState, useCallback } from 'react'
import { isEmpty } from 'lodash'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { ClosetModel } from 'types/models'
import { getClosetPromotions } from 'data/api'
import { transformClosets } from 'data/transformers/closet'
import { getFirstListedBreakpoint, useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { Screen } from 'constants/tracking/screens'
import { useAbTest } from '@marketplace-web/shared/ab-tests'

type Props = {
  isEnabled: boolean
}

const useFetchClosetPromotions = ({ isEnabled }: Props) => {
  const breakpoints = useBreakpoint()
  const isClosetPromotionKillswitchEnabled = useFeatureSwitch('killswitch_closet_promotion_web')
  const isInsertChangeEnabled = useAbTest('web_homepage_insert_positions_update')?.variant === 'on'

  const [closets, setClosets] = useState<Array<ClosetModel>>([])

  const fetchClosetPromo = useCallback(async () => {
    const shouldFetch = isEnabled && !isClosetPromotionKillswitchEnabled

    if (!shouldFetch) return null

    const userIds = closets.map(closet => closet.user.id)

    const breakpoint = getFirstListedBreakpoint(breakpoints.active) || 'phones'

    const breakpointToClosetPromoCount = {
      wide: isInsertChangeEnabled ? 4 : 3,
      desktops: 4,
      tablets: 5,
      phones: 5,
    }

    const closetPromoCount = breakpointToClosetPromoCount[breakpoint]

    const response = await getClosetPromotions({
      perPage: closetPromoCount,
      excludedUserIds: userIds,
      screenName: Screen.Homepage,
    })

    if ('errors' in response) return null

    const transformedClosets = transformClosets(response.promoted_closets)

    if (isEmpty(transformedClosets)) return null

    const isFirstFetch = !closets.length && transformedClosets.length > 0

    if (isFirstFetch && transformedClosets[0]) {
      transformedClosets[0].showBanner = true
    }

    return () => setClosets(prevArray => [...prevArray, ...transformedClosets])
  }, [
    breakpoints.active,
    isEnabled,
    isClosetPromotionKillswitchEnabled,
    closets,
    isInsertChangeEnabled,
  ])

  return { closets, fetchClosetPromo }
}

export default useFetchClosetPromotions
