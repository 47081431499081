'use client'

import { PropsWithChildren } from 'react'
import { InView } from 'react-intersection-observer'

type Props = { itemType?: string; onVisible: () => void }

const INSERT_TYPES = ['closet_promotion', 'ad', 'closet_or_ad']

const InsertVisibilityObserver = ({ children, itemType, onVisible }: PropsWithChildren<Props>) => {
  if (itemType && INSERT_TYPES.includes(itemType))
    return <InView onChange={inView => inView && onVisible()}>{children}</InView>

  return children
}

export default InsertVisibilityObserver
