'use client'

import { useState } from 'react'

import useExposeTestsOnHomePage from 'hooks/useExposeTestsOnHomePage'
import { ContentLoader, ErrorBoundary } from '@marketplace-web/shared/ui-helpers'
import SellerPromotion from 'components/SellerPromotion'
import { ResponseError } from 'types/api'

import BannersProvider from 'contexts/BannersProvider'
import { HomepageTabModel } from 'types/models/homepage-blocks'

import useFetchHomepageBlocks from './hooks/useFetchHomepageBlocks'
import BlockArrangement from './common/BlockArrangement'
import ErrorModal from './common/ErrorModal'
import HomeTabs from './HomeTabs'
import HomeBlocks from './HomeBlocks'
import HomeBanners from './HomeBanners'
import Feed from './Feed'
import useTabs from './hooks/useTabs'
import HomeProvider, { useHomeContext } from './HomeProvider'
import useHomeObservability from './hooks/useHomeObservability'
import BlocksFetcher from './BlocksFetcher'

type Props = {
  visitsCount: number
}

const Home = ({ visitsCount }: Props) => {
  const [showErrorModal, setShowErrorModal] = useState(false)

  const { currentTab } = useTabs()
  const { homepageSessionId } = useHomeContext()
  const { blocks, areBlocksLoading, fetchNewTab, error, fetchMoreBlocks } = useFetchHomepageBlocks()
  const { trackBoundaryError, trackEmptyFeed } = useHomeObservability(error)

  const hasNextPage = !!blocks?.nextPageToken

  useExposeTestsOnHomePage()

  const handleEmptyFeed = (feedError: ResponseError) => {
    trackEmptyFeed(feedError)
    if (error) setShowErrorModal(true)
  }

  const closeModal = () => setShowErrorModal(false)

  const fetchMore = () => {
    if (!blocks?.nextPageToken) return

    fetchMoreBlocks({
      tab: currentTab,
      homepageSessionId,
      nextPageToken: blocks.nextPageToken,
    })
  }

  return (
    <BannersProvider>
      <ErrorBoundary FallbackComponent={ErrorModal} preventLog onError={trackBoundaryError}>
        <HomeTabs
          onTabClick={tab => fetchNewTab({ tab, homepageSessionId, nextPageToken: null })}
        />
        {currentTab.isSellerPromotionEnabled && <SellerPromotion visitsCount={visitsCount} />}
        <div className="container">
          <div className="homepage">
            <HomeBanners />
            {blocks && <HomeBlocks blocks={blocks} />}
            {hasNextPage && !areBlocksLoading && (
              <BlocksFetcher
                fetchMoreBlocks={fetchMore}
                showLoadMoreButton={blocks.showLoadMoreButton}
              />
            )}
            {areBlocksLoading && (
              <ContentLoader
                size={ContentLoader.Size.Large}
                styling={ContentLoader.Styling.Tight}
                testId="homepage-loader"
              />
            )}
            {!hasNextPage && !areBlocksLoading && !currentTab.isFeedLoadedInHomepageApi && (
              <BlockArrangement afterBlockSpacer={false}>
                <Feed onEmpty={handleEmptyFeed} />
              </BlockArrangement>
            )}
          </div>
        </div>
        {showErrorModal && <ErrorModal enableClose onCloseClick={closeModal} />}
      </ErrorBoundary>
    </BannersProvider>
  )
}

type HomeWithProviderProps = Props & {
  homepageSessionId: string
  tabs: Array<HomepageTabModel>
}

const HomeWithProvider = (props: HomeWithProviderProps) => (
  <HomeProvider {...props}>
    <Home {...props} />
  </HomeProvider>
)

export default HomeWithProvider
