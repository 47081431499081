import { getHomepageReference, getHomepageTab, getPromoBox } from 'data/api'
import { isResponseError, isResponseSuccessful } from '_libs/utils/api'
import { ResponseError } from 'types/api'
import {
  BlocksReferenceDto,
  HomepageBlockEntity,
  HomepageBlockEntityDto,
  HomepageBlocksDto,
} from 'types/dtos'
import { ResponseCode } from 'data/api/response-codes'
import { BlockEntityType } from 'constants/home'
import { logHomeMessage } from 'pages/Home/utils/observability'

import { FetchHomepageBlocksOptions } from '../types'

export const EMPTY_RESPONSE_ERROR = {
  status: 200,
  code: ResponseCode.Ok,
  message: 'empty',
  errors: [],
  exception: null,
} as const satisfies ResponseError

const isReferenceBlock = (
  block: HomepageBlockEntityDto,
): block is HomepageBlockEntity<BlockEntityType.BlocksReference, BlocksReferenceDto> =>
  block.type === BlockEntityType.BlocksReference

const isNotResponseError = <T extends NonNullable<unknown>>(
  result: T | ResponseError,
): result is T => !('errors' in result)

export const getHomepageBlocks = async ({
  homepageSessionId,
  nextPageToken,
  tab,
}: FetchHomepageBlocksOptions): Promise<HomepageBlocksDto | ResponseError> => {
  const isFirstPage = !nextPageToken
  const loadPromoBox = tab.isPromoBoxEnabled && isFirstPage
  const promoBoxPromise = loadPromoBox ? getPromoBox() : null
  const tabResponse = await getHomepageTab({ tabName: tab.name, homepageSessionId, nextPageToken })

  if (isResponseError(tabResponse)) {
    logHomeMessage(`Blocks error: ${tabResponse.message}`, `tab: ${tab.name}`)

    return tabResponse
  }

  const blockPromises = tabResponse.blocks.map(async block => {
    if (isReferenceBlock(block)) {
      const referenceResponse = await getHomepageReference(block.entity.name, { homepageSessionId })

      if (isResponseError(referenceResponse)) return referenceResponse

      return referenceResponse.blocks
    }

    return block
  })

  const [promoBoxResponse, ...resolvedBlocks] = await Promise.all([
    promoBoxPromise,
    ...blockPromises,
  ])
  const blocks = resolvedBlocks.filter(isNotResponseError).flat()

  if (blocks.length === 0) {
    const error = resolvedBlocks.find(isResponseError)

    if (error) logHomeMessage(`Blocks error: ${error.message}`, `tab: ${tab.name}`)

    return error ?? EMPTY_RESPONSE_ERROR
  }

  return {
    promo_box:
      promoBoxResponse && isResponseSuccessful(promoBoxResponse)
        ? promoBoxResponse.promo_box
        : null,
    blocks,
    next_page_token: tabResponse.pagination.next_page_token,
    show_load_more_button: tabResponse.load_more_button,
  }
}
