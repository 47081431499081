'use client'

import { useEffect } from 'react'

import PersonalizationBanner from 'components/PersonalizationBanner'
import NetPromoterScore from 'components/NetPromoterScore'
import EmailConfirmationBanner from 'components/EmailConfirmationBanner'

import PortalDraftItemReminderBanner from 'components/PortalDraftItemReminderBanner'
import OnboardingModal from 'components/OnboardingModal'
import CatalogRulesBanner from 'components/CatalogRulesBanner'
import TermsAndConditionsBanner from 'components/TermsAndConditionsBanner'
import BeyondFashionBanner from 'components/BeyondFashionBanner'

import useBanners from 'hooks/useBanners'

import { TaxpayerBannerInHome } from '@marketplace-web/domain/taxpayers'

const TopBanners = () => {
  const { fetchBanners, banners } = useBanners()

  useEffect(() => {
    fetchBanners()
  }, [fetchBanners])

  function renderBanner<T extends keyof typeof banners>(
    name: T,
    BannerComponent: React.ComponentType<{ banner: NonNullable<(typeof banners)[T]> }>,
  ) {
    const banner = banners[name]

    if (!banner) return null

    return <BannerComponent banner={banner} />
  }

  return (
    <div className="homepage-top-banners">
      {renderBanner('taxpayerBanner', TaxpayerBannerInHome)}
      {renderBanner('feedPersonalizationBanner', PersonalizationBanner)}
      {renderBanner('portalMergeDraftItemsReminder', PortalDraftItemReminderBanner)}
      {renderBanner('nps', NetPromoterScore)}
      {renderBanner('emailConfirmation', EmailConfirmationBanner)}
      {renderBanner('termsAndConditions', TermsAndConditionsBanner)}
      {renderBanner('catalogRules', CatalogRulesBanner)}
      {renderBanner('multiVariantsOnboardingModal', OnboardingModal)}
      {renderBanner('beyondFashion', BeyondFashionBanner)}
    </div>
  )
}

export default TopBanners
