import { useEffect, useRef, useReducer } from 'react'

import { transformHomepageBlockResponse } from 'data/api/transformers/response'
import { ResponseError } from 'types/api'
import { isResponseError } from '_libs/utils/api'
import useLatestCallback from 'hooks/useLatestCallback'
import { logHomeMessage, observeFetchDuration } from 'pages/Home/utils/observability'

import { EMPTY_RESPONSE_ERROR, getHomepageBlocks } from '../utils/api'
import useTabs from './useTabs'
import { useHomeContext } from '../HomeProvider'
import { blocksReducer } from '../utils/blocks-reducer'
import { FetchHomepageBlocksOptions } from '../types'

const withDurationTracking =
  <T, A extends Array<unknown>>(fn: (...args: A) => Promise<T | ResponseError>) =>
  async (...args: A) => {
    const start = performance.now()
    const result = await fn(...args)
    const end = performance.now()
    const state = isResponseError(result) ? 'failure' : 'success'

    observeFetchDuration(end - start, { state, api_type: 'new' })

    return result
  }

const useFetchHomepageBlocks = () => {
  const { currentTabName, tabs } = useTabs()
  const { homepageSessionId } = useHomeContext()
  const initialTabName = useRef(currentTabName)

  const getBlocks = withDurationTracking(getHomepageBlocks)

  const [state, dispatch] = useReducer(blocksReducer, {
    isLoading: true,
    error: null,
    blocks: null,
  })

  const fetchNewTab = useLatestCallback(async (args: FetchHomepageBlocksOptions) => {
    dispatch({ type: 'loading' })

    const response = await getBlocks(args)

    if ('errors' in response) {
      dispatch({ type: 'error', payload: response })
    } else {
      dispatch({ type: 'success', payload: transformHomepageBlockResponse(response) })
    }
  })

  const fetchMoreBlocks = async (args: FetchHomepageBlocksOptions) => {
    dispatch({ type: 'more-blocks-loading' })

    const response = await getBlocks(args)

    if ('errors' in response) {
      dispatch({ type: 'more-blocks-failure' })

      if (response !== EMPTY_RESPONSE_ERROR) {
        logHomeMessage(
          `Blocks error: failed to load more blocks. ${response.message}`,
          `tab: ${args.tab.name}`,
        )
      }

      return
    }

    dispatch({
      type: 'more-blocks-success',
      payload: transformHomepageBlockResponse(response),
    })
  }

  useEffect(() => {
    // It is validated before that the first tab exists
    const currentTab = tabs.find(tab => tab.name === initialTabName.current) || tabs[0]!

    fetchNewTab({
      tab: currentTab,
      homepageSessionId,
      nextPageToken: null,
    })
  }, [fetchNewTab, tabs, homepageSessionId])

  return {
    blocks: state.blocks,
    fetchNewTab,
    fetchMoreBlocks,
    areBlocksLoading: state.isLoading,
    error: state.error,
  }
}

export default useFetchHomepageBlocks
