import { useAbTest, useTrackAbTest } from '@marketplace-web/shared/ab-tests'

const useExposeTestsOnHomePage = () => {
  useTrackAbTest(useAbTest('bpf_discount_hvf_v2'))
  useTrackAbTest(useAbTest('bpf_pricing_change_v1_a'))
  useTrackAbTest(useAbTest('bpf_pricing_change_v1_b'))
  useTrackAbTest(useAbTest('bpf_tiered_pricing_multiple_markets_v1'))
  useTrackAbTest(useAbTest('remove_direct_user_messaging'))
}

export default useExposeTestsOnHomePage
