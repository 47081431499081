'use client'

import { Button, Cell, Image, Spacer, Text } from '@vinted/web-ui'
import classNames from 'classnames'
import { useRef } from 'react'
import { InView } from 'react-intersection-observer'

import { BannerLayoutModel } from 'types/models'
import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import HorizontalScrollArea from 'components/HorizontalScrollArea'
import { Screen } from 'constants/tracking/screens'
import {
  userClickHomepageElement,
  userViewHomepageElement,
} from '_libs/common/event-tracker/events'
import { ControlScrollType } from 'components/HorizontalScrollArea/HorizontalScrollArea'

import HomepageBlockLayout from '../HomepageBlockLayout'

type Props = {
  elements: Array<BannerLayoutModel>
  name: string
  position: number
  homepageSessionId: string
  id: string
}

type BannerProps = {
  banner: BannerLayoutModel
  type: 'wide' | 'narrow'
  position: number
  homepageSessionId: string
  blockName: string
}

type TextTheme = ComponentProps<typeof Text>['theme']

const themeMap = {
  light: 'inverse',
  dark: 'amplified',
}

const Banner = ({ banner, type, position, homepageSessionId, blockName }: BannerProps) => {
  const breakpoints = useBreakpoint()
  const { track } = useTracking()
  const isSeen = useRef(false)

  const { title, icon, cta, background, textTheme, description, foreground } = banner

  const isPhones = breakpoints.phones
  const device = isPhones ? 'mobile' : 'tablet'
  const breakpoint = breakpoints.wide ? 'desktop' : device

  const trackingData = {
    blockName,
    position,
    contentSource: banner.contentSource,
    contentSourceLabel: '',
    contentSourceId: banner.id,
    homepageSessionId,
    screen: Screen.NewsFeed,
  }

  const handleItemClick = () => {
    track(userClickHomepageElement(trackingData))
  }

  const handleItemView = (inView: boolean) => {
    if (!inView) return
    if (isSeen.current) return

    isSeen.current = true

    track(userViewHomepageElement(trackingData))
  }

  return (
    <InView
      className={classNames('block-banner__container', {
        'block-banner__container--narrow': type === 'narrow',
      })}
      onChange={handleItemView}
    >
      <Image
        scaling={Image.Scaling.Cover}
        src={background.url}
        color={background.dominantColor}
        alt=""
      />
      {foreground && (
        <div className={`block-banner__foreground block-banner__foreground--${type}-${breakpoint}`}>
          <Image scaling={Image.Scaling.Fill} src={foreground.url} alt="" />
        </div>
      )}
      <div className="block-banner__info">
        <Cell
          styling={Cell.Styling.Tight}
          theme="transparent"
          url={cta.url}
          aria={{
            'aria-label': cta.accessibilityLabel || '',
          }}
          onClick={handleItemClick}
          testId="homepage-layouts-banner-cta"
        >
          <div className="block-banner__info-content">
            <div className={`block-banner__header-${type}-${breakpoint}`}>
              <Cell
                theme="transparent"
                prefix={icon?.url && <Image src={icon?.url} size={Image.Size.Regular} alt="" />}
                body={
                  title && (
                    <div
                      className={classNames('block-banner__title', {
                        'block-banner__title--phones': isPhones,
                      })}
                    >
                      <Text
                        text={title}
                        width={Text.Width.Parent}
                        type={isPhones ? Text.Type.Subtitle : undefined}
                        as="h2"
                        theme={themeMap[textTheme] as TextTheme}
                      />
                    </div>
                  )
                }
                styling={Cell.Styling.Tight}
              />
              {(icon || title) && <Spacer />}
              <div
                className={classNames('block-banner__description', {
                  'block-banner__description--phones': isPhones,
                })}
              >
                <Text
                  text={description}
                  type={isPhones ? Text.Type.Title : Text.Type.Heading}
                  width={Text.Width.Parent}
                  as="p"
                  theme={themeMap[textTheme] as TextTheme}
                  tabIndex={0}
                />
              </div>
            </div>
            <div className="block-banner__cta">
              <Button
                text={cta.title}
                theme={cta.theme}
                inverse={cta.inverse}
                size={isPhones ? Button.Size.Small : Button.Size.Medium}
                inline
                styling={Button.Styling.Filled}
                truncated
              />
            </div>
          </div>
        </Cell>
      </div>
    </InView>
  )
}

const BannersBlock = ({ elements, name, position, homepageSessionId, id }: Props) => {
  const bannerType = elements.length === 1 ? 'wide' : 'narrow'

  const renderBanners = () =>
    elements.map((banner, index) => (
      <Banner
        key={index}
        banner={banner}
        type={bannerType}
        position={index + 1}
        homepageSessionId={homepageSessionId}
        blockName={name}
      />
    ))

  return (
    <HomepageBlockLayout
      name={name}
      position={position}
      homepageSessionId={homepageSessionId}
      id={id}
      body={
        <HorizontalScrollArea controlsScrollType={ControlScrollType.Partial}>
          <div className={`${bannerType}-banners-layout-container`}>{renderBanners()}</div>
        </HorizontalScrollArea>
      }
    />
  )
}

export default BannersBlock
