'use client'

import { Dialog } from '@vinted/web-ui'

import { useAbTest, useTrackAbTest } from '@marketplace-web/shared/ab-tests'
import { MultiVariantsOnboardingModalModel } from 'types/models'

import useOnboarding from './hooks/useOnboarding'
import OnboardingTextVariant from './OnboardingTextVariant'
import OnboardingTextActions from './OnboardingTextActions'

type Props = {
  banner: MultiVariantsOnboardingModalModel
}

const OnboardingModal = ({ banner }: Props) => {
  const { isOpen, setSlide, onModalClose, onPrimaryClick } = useOnboarding({
    banner,
  })

  useTrackAbTest(useAbTest('video_onboarding_test_text_image'))
  useTrackAbTest(useAbTest('first_time_listing_guideline_v3'))

  const renderContent = () => {
    return (
      <>
        <OnboardingTextVariant banner={banner} onSlideChange={setSlide} onClose={onModalClose} />
        <OnboardingTextActions
          primaryText={banner.actions.primary.title}
          onPrimaryClick={onPrimaryClick}
        />
      </>
    )
  }

  return (
    <Dialog
      show={isOpen}
      defaultCallback={onModalClose}
      closeOnOverlay
      className="u-overflow-visible u-position-relative u-zindex-bump"
    >
      {renderContent()}
    </Dialog>
  )
}

export default OnboardingModal
