'use client'

import { Card, Cell, Text } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'
import { useAbTest } from '@marketplace-web/shared/ab-tests'
import { AbTestVariant } from 'constants/abtest'

type Props = {
  suffix?: JSX.Element
  prefix?: JSX.Element
}

const Banner = ({ prefix, suffix }: Props) => {
  const shippingFeesInfoBannerV1 = useAbTest('info_banner_shipping_fees_apply_feed')

  const translate = useTranslate('shipping_fees_applied_info_banner')

  if (!shippingFeesInfoBannerV1 || shippingFeesInfoBannerV1.variant === AbTestVariant.Off) {
    return null
  }

  return (
    <>
      {prefix}
      <Card testId="shipping-fees-applied-banner">
        <Cell
          title={<Text as="h3" text={translate('title')} type={Text.Type.Subtitle} />}
          theme="transparent"
        />
      </Card>
      {suffix}
    </>
  )
}

export default Banner
