'use client'

import { Button, Text } from '@vinted/web-ui'

import Banner from 'components/Banner'
import { useTracking } from '@marketplace-web/shared/event-tracker'

import { USER_PERSONALIZATION_SIZES_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { clickEvent } from '_libs/common/event-tracker/events'
import { FeedPersonalizationBannerModel } from 'types/models'

type Props = {
  banner: Pick<
    FeedPersonalizationBannerModel,
    'bottomTitle' | 'bottomSubtitle' | 'bottomActionTitle'
  >
}

const PersonalizationBanner = ({ banner }: Props) => {
  const { track } = useTracking()

  const handleClick = () => {
    const event = clickEvent({
      target: ClickableElement.PersonalizationButtonAfterFeed,
      targetDetails: 'feed_personalization_banner',
    })

    track(event)
  }

  const renderTitle = () => {
    return <Text text={banner.bottomTitle} type={Text.Type.Heading} as="h2" />
  }

  const actions = [
    <Button
      text={banner.bottomActionTitle}
      url={USER_PERSONALIZATION_SIZES_URL}
      onClick={handleClick}
      styling={Button.Styling.Filled}
    />,
  ]

  return (
    <Banner
      title={renderTitle()}
      body={<Text text={banner.bottomSubtitle} as="p" />}
      actions={actions}
    />
  )
}

export default PersonalizationBanner
