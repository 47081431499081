'use client'

import { useRef } from 'react'
import { Tabs } from '@vinted/web-ui'

import { scrollToTop } from '_libs/utils/window'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { userClickHomepageVertical } from '_libs/common/event-tracker/events'
import { HomepageTabModel } from 'types/models/homepage-blocks'

import useTabs from '../hooks/useTabs'
import { scrollToTab } from '../utils/tabs'
import { useHomeContext } from '../HomeProvider'

type Props = {
  onTabClick: (tabName: HomepageTabModel) => void
}

const HomeTabs = ({ onTabClick }: Props) => {
  const { track } = useTracking()
  const { currentTab, shouldShowTabs, navigateToTab, tabs } = useTabs()
  const containerRef = useRef<HTMLDivElement>(null)
  const { homepageSessionId } = useHomeContext()

  if (!shouldShowTabs) return null

  const handleClick = (tabName: string) => {
    scrollToTab(tabName, containerRef.current)
    scrollToTop()
    track(
      userClickHomepageVertical({
        homepageSessionId,
        target: tabName,
      }),
    )

    const newTab = tabs.find(tab => tab.name === tabName)
    if (!newTab) return

    onTabClick(newTab)

    if (tabName === currentTab.name) return

    navigateToTab(tabName)
  }

  const tabItems = Object.values(tabs).map(({ name, title }) => ({ title, id: name }))

  return (
    <div className="homepage-tabs">
      <div className="homepage-tabs__content" ref={containerRef}>
        <Tabs
          items={tabItems}
          activeItemId={currentTab.name}
          onClick={item => handleClick(item.id)}
        />
      </div>
    </div>
  )
}

export default HomeTabs
