'use client'

import { MouseEvent } from 'react'
import { Button, Cell, Text, Rating, Image, Spacer, Badge } from '@vinted/web-ui'

import { navigateToPage } from '_libs/utils/window'

import UserImage from 'components/UserImage'
import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import useTranslate from 'hooks/useTranslate'
import { useTracking } from '@marketplace-web/shared/event-tracker'

import { ClosetUserModel } from 'types/models'
import { clickListItemEvent } from '_libs/common/event-tracker/events'
import { ContentSource } from 'constants/tracking/content-sources'
import { ListItemContentType } from 'constants/tracking/content-types'
import { MEMBER_PROFILE_URL } from 'constants/routes'

type Props = {
  user: ClosetUserModel
  position: number
  isBusinessUser: boolean
  onClick?: () => void
  onViewProfileClick?: () => void
}

const ClosetUserInfo = ({ user, position, isBusinessUser, onClick, onViewProfileClick }: Props) => {
  const breakpoints = useBreakpoint()
  const translate = useTranslate('user')
  const businessTranslate = useTranslate('business')
  const { track } = useTracking()

  const { id, name, image, rating, ratingCount } = user
  const imageSize = breakpoints.phones ? Image.Size.Medium : Image.Size.Large
  const ratingSize = breakpoints.phones ? Rating.Size.Small : Rating.Size.Regular
  const cellStyling = breakpoints.phones ? Cell.Styling.Narrow : undefined

  function handleClick(event: MouseEvent) {
    event.preventDefault()

    track(
      clickListItemEvent({
        id: user.id,
        position,
        contentType: ListItemContentType.PromotedCloset,
        contentSource: ContentSource.PromotedClosets,
      }),
    )

    onClick?.()

    navigateToPage(MEMBER_PROFILE_URL(id))
  }

  function handleViewProfileClick(event: MouseEvent) {
    event.preventDefault()

    onViewProfileClick?.()

    navigateToPage(MEMBER_PROFILE_URL(id))
  }

  function renderTitle() {
    return (
      <>
        <a href={MEMBER_PROFILE_URL(id)} onClick={handleClick}>
          <Text type={Text.Type.Title} as="span">
            {name}
          </Text>
        </a>
        {isBusinessUser && (
          <>
            <Spacer size={Spacer.Size.Small} orientation={Spacer.Orientation.Vertical} />
            <Badge content={businessTranslate('badge')} />
          </>
        )}
      </>
    )
  }

  return (
    <Cell
      prefix={
        <a href={MEMBER_PROFILE_URL(id)} onClick={handleClick}>
          <UserImage src={image} size={imageSize} styling={Image.Styling.Circle} />
        </a>
      }
      suffix={
        <Button
          size={Button.Size.Small}
          text={translate('action.view_profile')}
          url={MEMBER_PROFILE_URL(id)}
          onClick={handleViewProfileClick}
        />
      }
      title={renderTitle()}
      body={
        <Rating
          aria={{
            'aria-label': (rating && translate('a11y.rating', { rating, max_rating: 5 })) || '',
          }}
          value={rating}
          text={ratingCount}
          emptyStateText={translate('feedback.no_reviews')}
          size={ratingSize}
        />
      }
      styling={cellStyling}
    />
  )
}

export default ClosetUserInfo
