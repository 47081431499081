'use client'

import { Text } from '@vinted/web-ui'
import { useInView } from 'react-intersection-observer'
import classNames from 'classnames'

import useTranslate from 'hooks/useTranslate'
import { useSession } from '@marketplace-web/shared/session'

import { Screen } from 'constants/tracking/screens'
import useShowHomeTabs from 'pages/Home/hooks/useShowHomeTabs'

import CloseButton from '../CloseButton'
import StaticBanner from '../StaticBanner'

type Props = {
  onClose?: () => void
}

const TAB_HEIGHT = 46 // equals to $header-tabs-height
const HEADER_TOTAL_HEIGHT = 97 // equals to $header-total-height

const StickyBanner = ({ onClose }: Props) => {
  const { screen } = useSession()
  const isHomePage = screen === Screen.NewsFeed

  const shouldShowTabs = useShowHomeTabs()
  const isTabsVisible = shouldShowTabs && isHomePage

  const [ref, inView] = useInView({
    rootMargin: isTabsVisible
      ? `-${HEADER_TOTAL_HEIGHT + TAB_HEIGHT}px 0px 0px 0px`
      : `-${HEADER_TOTAL_HEIGHT}px 0px 0px 0px`, // Adjust the top margin by header height
  })
  const translate = useTranslate('shipping_fees_applied_info_banner')

  const renderStickyBanner = () => (
    <div
      data-testid="shipping-fees-applied-banner-sticky"
      className={classNames(
        'shipping-fees-applied-banner--sticky',
        isTabsVisible && 'shipping-fees-applied-banner--sticky-with-tabs',
      )}
    >
      <Text text={translate('title')} type={Text.Type.Subtitle} as="div" />
      {onClose && <CloseButton onClick={onClose} />}
    </div>
  )

  return (
    <>
      <div ref={ref} />
      {inView && <StaticBanner onClose={onClose} />}

      {!inView && renderStickyBanner()}
    </>
  )
}

export default StickyBanner
