'use client'

import { createContext } from 'react'

import { HomepageTabModel } from 'types/models/homepage-blocks'

export type HomeContextType = {
  homepageSessionId: string
  tabs: Array<HomepageTabModel>
}

const HomeContext = createContext<HomeContextType>({
  // These fallback values will never be used, as the provider is required
  homepageSessionId: '',
  tabs: [],
})

export default HomeContext
