'use client'

import { MouseEvent } from 'react'
import { useInView } from 'react-intersection-observer'

import { useTracking } from '@marketplace-web/shared/event-tracker'

import { ClosetPromotionItemDto } from 'types/dtos'
import { transformClosetPromotionItemDtoToProductItem } from 'data/transformers/product-item'
import { ContentSource } from 'constants/tracking/content-sources'
import { impressionEvent } from '_libs/common/event-tracker/events'
import { ListItemContentType } from 'constants/tracking/content-types'

import { ProductItem } from 'components/ProductItem'

type Props = {
  item: ClosetPromotionItemDto
  onClick?: (event: MouseEvent) => void
  onItemFavouriteToggle?: (payload: {
    itemId: number
    isFollowEvent: boolean
    itemContentSource?: ContentSource | null
  }) => void
  showPhotoCollage: boolean
  index: number
  homepageSessionId?: string
  isViewingSelf: boolean
}

const ClosetItem = ({
  item,
  onClick,
  onItemFavouriteToggle,
  showPhotoCollage,
  index,
  homepageSessionId,
  isViewingSelf,
}: Props) => {
  const { track } = useTracking()
  const { ref } = useInView({
    triggerOnce: true,
    onChange: inView => {
      if (!inView) return
      track(
        impressionEvent({
          id: item.id,
          position: index + 1,
          contentType: ListItemContentType.Item,
          contentSource: ContentSource.PromotedClosets,
          itemOwnerId: item.user_id,
          homepageSessionId,
        }),
      )
    },
  })

  return (
    <div ref={ref} className="u-fill-height" data-testid={`closet-item-${item.id}`}>
      <ProductItem
        item={transformClosetPromotionItemDtoToProductItem(item)}
        showOwner={false}
        onClick={onClick}
        onFavouriteToggle={onItemFavouriteToggle}
        testId={`item-${item.id}`}
        hideBusinessBadge
        showPhotoCollage={showPhotoCollage}
        viewingSelf={isViewingSelf}
      />
    </div>
  )
}

export default ClosetItem
