'use client'

import { Fragment } from 'react'

import { BlockEntityType, ThumbnailsBlockStyle } from 'constants/home'
import {
  HomepageBlockEntityModel,
  HomepageItemBlockModel,
  HomepageBlocksModel,
  HomepageItemModel,
} from 'types/models/homepage-blocks'
import { BannersBlockModel, GenericPromoBoxModel, ThumbnailsBlockModel } from 'types/models'
import useStickyPromoBox from '_libs/common/braze/hooks/useStickyPromoBox'
import ExposureBlock from 'components/ExposureBlock'
import useBrazePromoBoxes from '_libs/common/braze/hooks/useBrazePromoBoxes'
import { PromoBoxType } from '_libs/common/braze/constants'

import ItemsBlock from './ItemsBlock'
import { HorizontalRowsBlock, ThumbnailsBlock, BannersBlock } from './Layouts'
import BlockArrangement from '../common/BlockArrangement'
import { useHomeContext } from '../HomeProvider'
import useTabs from '../hooks/useTabs'
import Item from './Item'
import PromoBox from './PromoBox'
import Header from './Header'

type Props = {
  blocks: HomepageBlocksModel
}

const PARTIAL_WIDTH_BLOCKS = [BlockEntityType.Item, BlockEntityType.PromoBox]

const HomeBlocks = ({ blocks }: Props) => {
  const { getStickyPromoBox } = useStickyPromoBox(blocks.promoBox)
  const { homepageSessionId } = useHomeContext()
  const { currentTab } = useTabs()

  const brazePromoBoxes = useBrazePromoBoxes(PromoBoxType.Braze)
  const enabledPromoBoxes = currentTab.feed.isPromoBoxEnabled ? brazePromoBoxes : []

  if (!blocks.blocks.length) return null

  const renderHomePageGenericBlock = (block: HomepageItemBlockModel, index: number) => {
    return (
      <BlockArrangement>
        <ItemsBlock
          {...block}
          promoBox={currentTab.isPromoBoxEnabled ? getStickyPromoBox() : null}
          homepageSessionId={homepageSessionId}
          position={index + 1}
        />
      </BlockArrangement>
    )
  }

  const renderThumbnailsBlock = (block: ThumbnailsBlockModel, index: number) => {
    if (!block.elements.length) return null

    if (block.style === ThumbnailsBlockStyle.TwoHorizontalRows) {
      return (
        <BlockArrangement>
          <HorizontalRowsBlock
            {...block}
            position={index + 1}
            homepageSessionId={homepageSessionId}
          />
        </BlockArrangement>
      )
    }

    return (
      <BlockArrangement>
        <ThumbnailsBlock {...block} homepageSessionId={homepageSessionId} position={index + 1} />
      </BlockArrangement>
    )
  }

  const renderBannersBlock = (block: BannersBlockModel, index: number) => {
    if (!block.elements.length) return null

    return (
      <BlockArrangement>
        <BannersBlock {...block} position={index + 1} homepageSessionId={homepageSessionId} />
      </BlockArrangement>
    )
  }

  const renderItem = (item: HomepageItemModel, index: number) => {
    return (
      <Item
        item={item}
        position={index + 1}
        homepageSessionId={homepageSessionId}
        brazePromoBoxes={enabledPromoBoxes}
      />
    )
  }

  const renderPromoBox = (promoBox: GenericPromoBoxModel, index: number) => {
    return <PromoBox promoBox={promoBox} position={index + 1} brazePromoBoxes={enabledPromoBoxes} />
  }

  const renderBlock = (block: HomepageBlockEntityModel, index: number) => {
    switch (block.type) {
      case BlockEntityType.ItemBoxBlock:
        return renderHomePageGenericBlock(block.entity, index)
      case BlockEntityType.ThumbnailsBlock:
        return renderThumbnailsBlock(block.entity, index)
      case BlockEntityType.BannersBlock:
        return renderBannersBlock(block.entity, index)
      case BlockEntityType.Item:
        return renderItem(block.entity, index)
      case BlockEntityType.PromoBox:
        return renderPromoBox(block.entity, index)
      default:
        return null
    }
  }

  let fullWidthIndex = -1
  let partialWidthIndex = -1

  return (
    <div className="homepage-blocks">
      {blocks.blocks.map(block => {
        if (block.type === BlockEntityType.ExposureBlock) {
          return <ExposureBlock {...block.entity} key={block.entity.test_id} />
        }

        if (block.type === BlockEntityType.Header) {
          return <Header {...block.entity} key={block.entity.title} />
        }

        const isPartialWidth = PARTIAL_WIDTH_BLOCKS.includes(block.type)

        if (isPartialWidth) {
          partialWidthIndex += 1
        } else {
          fullWidthIndex += 1
        }

        const index = isPartialWidth ? partialWidthIndex : fullWidthIndex

        return (
          <Fragment key={`${fullWidthIndex}-${partialWidthIndex}`}>
            {renderBlock(block, index)}
          </Fragment>
        )
      })}
    </div>
  )
}

export default HomeBlocks
