import { PROMO_BOX_INDEX_IN_FEED_ROW, PROMO_BOX_PAGE_LENGTH } from '_libs/common/braze/constants'

export const feedInsertPositions = {
  first: {
    wide: 10,
    desktops: 8,
    tablets: 6,
    phones: 6,
  },
  inbetweenAdFirst: {
    wide: 20,
    desktops: 16,
    tablets: 15,
    phones: 14,
  },
  promoBoxFirst: PROMO_BOX_INDEX_IN_FEED_ROW,
  promoBoxDistance: PROMO_BOX_PAGE_LENGTH,
  distance: {
    wide: 25,
    desktops: 20,
    tablets: 18,
    phones: 18,
  },
  newDistance: {
    wide: 25,
    desktops: 20,
    tablets: 21,
    phones: 20,
  },
} as const
