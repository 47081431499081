'use client'

import noop from 'lodash/noop'
import { useRef } from 'react'

import { ProductItem } from 'components/ProductItem'
import { addClickListItemTracking } from 'containers/TrackingProvider/hocs'
import { ListItemContentType } from 'constants/tracking/content-types'
import { impressionEvent } from '_libs/common/event-tracker/events'
import { GenericPromoBoxModel } from 'types/models/generic-promo-box'
import { HomepageItemModel } from 'types/models/homepage-blocks'
import { useTracking } from '@marketplace-web/shared/event-tracker'

import useVisible from '../../hooks/useVisible'
import ItemArrangement from '../../common/ItemArrangement'

const TrackedProductItem = addClickListItemTracking(ProductItem)

type Props = {
  item: HomepageItemModel
  position: number
  homepageSessionId: string
  brazePromoBoxes: Array<GenericPromoBoxModel>
}

const Item = (props: Props) => {
  const isSeen = useRef(false)
  const { track } = useTracking()

  const { item, position, homepageSessionId } = props

  const onVisible = () => {
    if (isSeen.current) return

    track(
      impressionEvent({
        id: item.id,
        position,
        contentType: ListItemContentType.Item,
        contentSource: item.contentSource,
        itemOwnerId: item.user.id,
        homepageSessionId,
      }),
    )

    isSeen.current = true
  }

  const ref = useVisible(onVisible)

  return (
    <ItemArrangement ref={ref} {...props}>
      <TrackedProductItem
        tracking={{
          id: item.id,
          contentType: ListItemContentType.Item,
          contentSource: item.contentSource,
          position,
          homepageSessionId,
        }}
        testId="feed-item"
        item={item}
        showStatus
        showStatusAsBadge
        onFavouriteToggle={noop}
      />
    </ItemArrangement>
  )
}

export default Item
