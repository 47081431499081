'use client'

import { useRef } from 'react'

import { isEqual } from 'lodash'

import { ClosetModel, VasEntryPointModel } from 'types/models'
import { HomeAdvertisement } from '@marketplace-web/domain/ads'
import { HomeClosetPromotion } from '@marketplace-web/domain/vas'
import { useHomeContext, useTabs } from '@marketplace-web/domain/home-page'

type Ad = 'ad' | 'fallback-ad'
type Entity = ClosetModel | 'empty' | Ad

const isAd = (item: Entity): item is Ad => item === 'ad' || item === 'fallback-ad'
const isEmpty = (item: Entity): item is 'empty' => item === 'empty'
const isCloset = (item: Entity): item is ClosetModel => !isAd(item) && !isEmpty(item)

type Props = {
  vasEntryPoints?: Array<VasEntryPointModel>
  closets: Array<ClosetModel>
}

const useHomeClosetOrAd = ({ vasEntryPoints, closets }: Props) => {
  const { currentTab } = useTabs()
  const { homepageSessionId } = useHomeContext()
  const renderedSequence = useRef<Array<Entity>>([])
  let currentIndex = 0

  const getRemainingCloset = () => {
    const remainingClosets = closets.filter(closet => {
      const wasClosetRendered = renderedSequence.current.some(value => isEqual(closet, value))

      return !wasClosetRendered
    })

    return remainingClosets[0]
  }

  const getNewClosetOrAd = (index: number): Entity => {
    const shouldRenderCloset = index % 2 === 0

    if (shouldRenderCloset) return getRemainingCloset() ?? 'fallback-ad'

    return 'ad'
  }

  const renderCloset = (closet: ClosetModel, position: number) => {
    if (!currentTab.feed.arePromotedClosetsEnabled) return null

    return (
      <HomeClosetPromotion
        position={position}
        vasEntryPoints={vasEntryPoints}
        closet={closet}
        homepageSessionId={homepageSessionId}
      />
    )
  }

  const renderFallbackCloset = (position: number, index: number) => {
    const renderedFallback = renderedSequence.current[index]

    if (renderedFallback && isCloset(renderedFallback))
      return renderCloset(renderedFallback, position)

    const fallback = getRemainingCloset()
    if (!fallback) {
      // Previously it rendered an ad, so we replace it to not render the ad again
      renderedSequence.current[index] = 'empty'

      return null
    }

    renderedSequence.current[index] = fallback

    return renderCloset(fallback, position)
  }

  const renderClosetOrAdComponent = (position: number, id: string | number) => {
    const index = currentIndex
    const entity = renderedSequence.current[index] ?? getNewClosetOrAd(index)
    renderedSequence.current[index] = entity

    currentIndex += 1

    if (isEmpty(entity)) return null
    if (isAd(entity)) {
      const renderFallback = () => entity === 'ad' && renderFallbackCloset(position, index)

      if (!currentTab.feed.areAdsEnabled) return renderFallback()

      return <HomeAdvertisement renderFallback={renderFallback} position={position} id={id} />
    }

    return renderCloset(entity, position)
  }

  return {
    renderClosetOrAdComponent,
  }
}

export default useHomeClosetOrAd
