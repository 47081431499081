export enum Banner {
  FeedPersonalizationBanner = 'feed_personalization_banner',
  Nps = 'nps',
  EmailConfirmation = 'email_confirmation',
  RecommendedSearchesViewCount = 'recommended_searches_view_count',
  TermsAndConditions = 'terms_and_conditions',
  PortalMergeDraftItemsReminder = 'portal_merge_draft_items_reminder',
  ListerActivation = 'lister_activation',
  BusinessAccount = 'business_account',
  CatalogRules = 'catalog_rules',
  BeyondFashion = 'beyond_fashion',
}
