'use client'

import { InView } from 'react-intersection-observer'
import { useRef } from 'react'
import { Spacer } from '@vinted/web-ui'

import { VasEntryPointModel } from 'types/models/vas-entry-point'
import { ClosetModel } from 'types/models/closet'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { impressionEvent } from '_libs/common/event-tracker/events'
import { ListItemContentType } from 'constants/tracking/content-types'
import { ContentSource } from 'constants/tracking/content-sources'

import { VasEntryPoint } from '../../constants/vas-entry-point'
import ClosetPromotion from './ClosetPromotion'

type Size = keyof typeof Spacer.Size

type DynamicSpacerProps = {
  phones: Size
  tabletsUp: Size
}

const DynamicSpacer = ({ phones, tabletsUp }: DynamicSpacerProps) => {
  return (
    <>
      <div className="u-phones-only">
        <Spacer size={Spacer.Size[phones]} />
      </div>
      <div className="u-tablets-up-only">
        <Spacer size={Spacer.Size[tabletsUp]} />
      </div>
    </>
  )
}

type Props = {
  closet: ClosetModel
  position: number
  vasEntryPoints?: Array<VasEntryPointModel>
  renderFallback?: () => JSX.Element
  homepageSessionId: string
}

const HomeClosetPromotion = (props: Props) => {
  const closetPromoBanner = props.closet.showBanner
    ? props.vasEntryPoints?.find(entryPoint => entryPoint.name === VasEntryPoint.PromotedClosets)
    : undefined

  const { track } = useTracking()
  const isSeen = useRef(false)

  const handleVisible = (inView: boolean) => {
    if (isSeen.current) return
    if (!inView) return

    isSeen.current = true

    track(
      impressionEvent({
        id: props.closet.user.id,
        position: props.position,
        contentType: ListItemContentType.PromotedCloset,
        contentSource: ContentSource.PromotedClosets,
        homepageSessionId: props.homepageSessionId,
      }),
    )
  }

  return (
    <>
      <DynamicSpacer phones="Regular" tabletsUp="Large" />
      <InView onChange={handleVisible}>
        <ClosetPromotion wide banner={closetPromoBanner} {...props} />
      </InView>
      <DynamicSpacer phones="Large" tabletsUp="X2Large" />
    </>
  )
}

export default HomeClosetPromotion
